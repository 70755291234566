
import {
  computed,
  defineComponent,
  onMounted,
  onBeforeUnmount,
  ref,
  watch
} from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { useMutation } from "@vue/apollo-composable";
import moment from "moment";

import { Conversation, Message } from "../constants/types";
import {
  SEND_MESSAGE_MUTATION,
  UPDATE_MESSAGE,
  DELETE_CONVERSATION
} from "../constants/gql/message";
import { getLink } from "@/utils/utils";
export default defineComponent({
  props: {
    refetch: Function
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const messageToSend = ref("");
    const ownUser = ref(store.state.user);
    const windowHeight = ref(window.innerHeight - 52);
    const windowWidth = ref(window.innerWidth);
    const isActiveDropdown = ref("");

    const resizeScreen = () => {
      windowHeight.value = window.innerHeight - 52;
      windowWidth.value = window.innerWidth;
    };

    onMounted(async () => {
      window.addEventListener("resize", resizeScreen);
      document.addEventListener("click", () => {
        isActiveDropdown.value = "";
      });
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", resizeScreen);
      document.addEventListener("click", () => {
        isActiveDropdown.value = "";
      });
    });

    watch(
      () => route.params.username,
      () => {
        setTimeout(() => {
          const div = document.getElementById("messages_container");
          if (div) {
            div.scrollTo(0, div.scrollHeight);
          }
        }, 10);
      }
    );

    const toggleDropDown = () => {
      if (isActiveDropdown.value == "") {
        isActiveDropdown.value = "is-active";
      } else {
        isActiveDropdown.value = "";
      }
    };

    const { mutate: deleteConv } = useMutation(DELETE_CONVERSATION);

    const deleteConversation = async () => {
      const { data } = await deleteConv({
        interlocutor: route.params.username
      });
      store.commit("loadMessages", data.deleteConv);
      router.push("/inbox");
    };

    const messages = computed(() => {
      setTimeout(() => {
        const div = document.getElementById("messages_container");
        if (div) {
          div.scrollTo(0, div.scrollHeight);
        }
      }, 100);
      return store.state.messages || [];
    });

    const conversations = computed(() => {
      return (store.state.conversations || []).map((conv: Conversation) => {
        return {
          ...conv,
          nbOfUnreadMessages: store.state.unreadMessages.filter(
            (m: Message) => m.from === conv.username
          ).length
        };
      });
    });

    const activeDiscussion = computed(() => {
      const currentId = route.params.username;
      const currentIdIsValid = conversations.value.some(
        (e: Conversation) => e.username === currentId
      );
      if (currentId != null && currentIdIsValid) {
        return currentId;
      }
    });

    const activeDiscussionMessages = computed(() => {
      return messages.value.filter((m: Message) => {
        return (
          m.from == activeDiscussion.value || m.to == activeDiscussion.value
        );
      });
    });

    const sameDayMessage = (index: number) => {
      const currentDate = activeDiscussionMessages.value[index].created_at;
      const prevDate = activeDiscussionMessages.value[index - 1].created_at;

      return (
        moment(currentDate).format("YYYYMMDD") !==
        moment(prevDate).format("YYYYMMDD")
      );
    };

    const { mutate } = useMutation(SEND_MESSAGE_MUTATION);
    const { mutate: updateMessage } = useMutation(UPDATE_MESSAGE);

    const sendMessage = async () => {
      if (messageToSend.value.trim() == "") return;
      const sentMessage = await mutate({
        input: {
          to: activeDiscussion.value,
          text: messageToSend.value
        }
      });
      store.commit("addMessage", sentMessage.data.newMessage);
      messageToSend.value = "";
    };

    const getProfilePicture = (username: string) => {
      if (username == ownUser.value.username)
        return getLink(ownUser.value.hashedUsername);
      const user = conversations.value.find(
        (el: Conversation) => el.username == username
      );
      if (user) {
        return getLink(user.hashedUsername);
      }
    };

    const redirectTo = async (inboxUsername: string) => {
      await updateMessage({
        from: inboxUsername
      });
      if (props.refetch) {
        props.refetch();
      }
      router.push("/inbox/" + inboxUsername);
    };

    return {
      moment,
      messages,
      conversations,
      activeDiscussion,
      activeDiscussionMessages,
      messageToSend,
      sendMessage,
      windowHeight,
      windowWidth,
      redirectTo,
      toggleDropDown,
      isActiveDropdown,
      sameDayMessage,
      getProfilePicture,
      getLink,
      deleteConversation
    };
  }
});
